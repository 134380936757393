import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Container, Row, Col } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowUp } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import "../components/css/media.scss";
import useMediaQuery from "../components/mediaQuery";
import { useKeenSlider } from "keen-slider/react"
import 'keen-slider/keen-slider.min.css'
import NewsList from "../components/data/newList.json"
// import VideoSlider from "../components/VideoSlider"
import {useState,useEffect,useContext} from "react"
import evtImg01 from "../images/media/pastevents-img1.jpg"
import evtImg02 from "../images/media/pastevents-img2.jpg"
import evtImg03 from "../images/media/pastevents-img3.jpg"
import evtImg04 from "../images/media/pastevents-img4.png"
import evtImg05 from "../images/media/events-img1.jpg"

import newsimg01 from "../images/media/newsimg002.webp"


import upevtImg01 from "../images/media/upcomingevents-img1.jpg"
import GlobalContext from "../context/GlobalContext"
import PressRelease from "../components/EdgeQ-SeriesB-Media-Deck.pdf"
import { Helmet } from "react-helmet";


const Media = ({location}) => {
    const [sliderRef1] = useKeenSlider({
        breakpoints: {
            "(max-width: 1199px)": {
              slides: { perView: 3, spacing:20 },
            },
            "(max-width: 991px)": {
              slides: { perView: 2, spacing: 5 },
            },
            "(max-width: 575px)": {
                slides: { perView: 2, spacing: 50 },
              },
          },
        loop: false,
        slides: {
          perView: 3,
          spacing: 30,
        },
      })
    const showRef = React.useRef(null)
    const showRef2 = React.useRef(null)
    const showRef3 = React.useRef(null)
    const showRef4 = React.useRef(null)
    React.useEffect(()=>{
        // window.scrollTo(0, 0);
      },[])
      
 //Load more for desktop
    const [visible, setVisible] = React.useState(4)

    const showMore = (index)=>{
        setVisible((prevVal)=> prevVal + 4)
        if(index >= 116){
        showRef.current.style.display = "none"
        }
        const cursor =  document.querySelector('.cursor');
        cursor.classList.add('grow');

    }

    const showLess = ()=>{
        setVisible((prevVal)=> prevVal - 112)  
        if(visible >= 116){
            showRef2.current.style.display = "block"
        }
    }
        //Load more for mobile
    const [visible1, setVisible1] = React.useState(4)

    const showMore2 = (index)=>{
        setVisible1((prevVal)=> prevVal + 4)
        if(index >= 116){
        showRef3.current.style.display = "none"
        }
    }

    const showLess2 = ()=>{
        setVisible1((prevVal)=> prevVal - 100)  
        if(visible1 >= 116){
            showRef4.current.style.display = "block"
        }
    }



    
    const [visiblePressCount, setVisiblePressCount] = useState(4)

    const handleLoadMore = (index) => {
 
        setVisiblePressCount((prevVal)=> prevVal + 4)
        if(index >= 12){
            showRef3.current.style.display = "none"
            }
     
        const cursor1 =  document.querySelector('.cursor');
        cursor1.classList.add('grow');
    }

    const [pressReleasesData, setPressReleasesData] = useState([
        {
            date: "Apr 19, 2023",
            LinkText:
                "EdgeQ Closes $75M Series-B Investment and Ramps to Customers’ Demand for Its Award-Winning",
            Link: "/edgeq-series-b-pr",
        },
        {
            date: "Mar 9, 2023",
            LinkText:
                "EdgeQ Wins Multiple 2023 Global Mobile (GLOMO) Awards Including the Coveted CTO Choice",
            Link: "/edgeq-wins-multiple",
        },
        {
            date: "Feb 27, 2023",
            LinkText:
                "EdgeQ and Vodafone Debut State of the Art, Software-Defined, maMIMO Open RAN Solution",
            Link: "/edgeq-and-vodafone-debut",
        },
        {
            date: "Oct 26, 2022",
            LinkText:
                "EdgeQ & Vodafone Partner on World’s First 5G/4G Software Defined ORAN",
            Link: "/edgeq-and-vodafone-partner",
        },
        {
            date: "Sep 13, 2022",
            LinkText:
                "EdgeQ Partners with Mavenir on Software Defined 4G and 5G Mobile Networks",
            Link: "/edgeq-partners-with-mavenir",
        },
        {
            date: "Jun 15, 2022",
            LinkText:
                "EdgeQ Showcases and Samples 5G Small Cell and O-RAN PCIe Accelerator Card",
            Link: "/edgeq-showcases-and-samples",
        },
        {
            date: "Feb 15, 2022",
            LinkText:
                "EdgeQ Adds Former Federal Communications Commission Chairman Ajit Pai to its Advisory Board",
            Link: "/edgeq-adds-former-fedral",
        },
        {
            date: "Aug 18, 2021",
            LinkText:
                "EdgeQ Samples World’s First Software Defined 5G Base Station-on-a-Chip to Market Leaders in Enterprise, Telco and Hyperscale Cloud",
            Link: "/edgeq-samples-worlds-first",
        },
        {
            date: "Jun 15, 2021",
            LinkText:
                "EdgeQ Launches Industry’s First 5G Chipset-as-a-Service for 5G Wireless Infrastructure Market",
            Link: "/edgeq-launches-industrys-first",
        },
        {
            date: "Jan 26, 2021",
            LinkText:
                "EdgeQ Adds Former Qualcomm CEO Paul Jacobs & CTO Matt Grob as Advisors to Disrupt the Current Closed RAN Ecosystem with RISC-V based Highly Programmable 5G and AI Platform",
            Link: "/edgeq-adds-former-fedral",
        },
        {
            date: "Nov 17, 2020",
            LinkText:
                "EdgeQ Raises $51M in Funding to Unify 5G and AI in an Open and Programmable Platform to Ignite Edge Networks",
            Link: "/edgeq-raises-51m-in",
        },
    ])


     


    const globalContext = useContext(GlobalContext)
       const {setIsAvailable,setIsDrag,closeClass,setcloseClass} = globalContext

       const [upcomingeventsSlider,setUpcomingeventsSlider]= useState([
        {
            date:"Feb 26 - Feb 29, 2024",
            evtName: "MWC Barcelona",
            evtLink:"https://www.mwcbarcelona.com/exhibitors/27096-edgeq", 
            evtImg: evtImg01,
        }
    ])

    useEffect(()=>{
        upcomingeventsSlider.forEach((slides,index)=>{
            slides.isChecked = false;
            slides.id = index
         })
       
    },[])


    
    const [eventsSlider,setEventsSlider]= useState([
        
        {
          date:"DEC 6-8, 2021",
          evtName: "RISC-V Summit",
          evtLink:"https://events.linuxfoundation.org/riscv-summit/", 
          evtImg: evtImg04,
        },
        {
            date:"NOV 15-16 , 2021",
            evtName: "Telecom Council Carrier Connections",
            evtLink:"https://telecomcouncil.cventevents.com/event/c05fb276-7dfb-4fc4-9b06-aca988bbf894/summary", 
            evtImg: evtImg05
        },
        {
            date:"OCT 26-28, 2021",
            evtName: "OPEN RAN SUMMIT",
            evtLink:"https://www.telecomtv.com/content/open-ran-summit-21/telecom-silicon-42706/", 
            evtImg: evtImg01
        },
        {
            date:"OCT 14 , 2021",
            evtName: "IEEE EIF",
            evtLink:"https://attend.ieee.org/wf-5g/eif-2021/", 
            evtImg: evtImg02
        },
        {
            date:"May 24 -25, 2022",
            evtName: "Small Cells World Summit",
            evtLink:"https://smallcells.world/london-agenda/", 
            evtImg: upevtImg01,
        }
    ])

    useEffect(()=>{
        eventsSlider.forEach((slides,index)=>{
            slides.isChecked = false;
            slides.id = index
         })
       
    },[])

    const showEvtDetails = (idx)=>{ 
    
  
        // setcloseClass(!closeClass)
   
      
        setEventsSlider(eventsSlider.map((sl)=>{
           if(sl.id === idx){
               return{...sl,isChecked:!sl.isChecked}
           }
           return sl
       }
   
       ))
       if(eventsSlider[idx].isChecked){
        setcloseClass(!closeClass)
   
       }
      
      
      
   }

   const mouseEvtEnter = (id)=>{
        if(eventsSlider[id].isChecked){
        //   setcloseClass(!closeClass)
        // setcloseClass(!closeClass)
        }  
    }

    const mouseEvtLeave = ()=>{
        // setcloseClass(false)
    } 

const matches = useMediaQuery("(max-width: 1024px)");


  return (

    <Layout>
      <Seo title="EdgeQ | Media" />
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home - EdgeQ" />
        <meta property="og:description" content="5G Base Station on-a-Chip 5G + AI | RISC-V Architecture | Open &amp; Programmable Learn More EdgeQ Samples World’s First Software Defined 5G Base Station-on-a-Chip to Market Leaders in Enterprise, Telco and Hyperscale Cloud Read More WATCHNEW VIDEO SERIES technology EdgeQ Technology We empower companies with an open and adaptable 5G+AI platform, hardware that is &hellip; Home Read More &raquo;" />
        <meta property="og:url" content="https://edgeq.io/" />
        <meta property="og:site_name" content="EdgeQ" />
        <meta property="article:modified_time" content="2021-10-26T15:39:25+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="6 minutes" />
      </Helmet>
     <main className="mediaPage">
      <section className="media-first-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="media-heading">
                <h2 data-aos="fade-down">
                    what’s new at <span className="orange">EDGEQ?</span>
                </h2>
              </div>
            </Col>
          </Row>
            <section className="press-our-sec">
                <div className="press-buttons">
                    <a href="#Media" class="ExpertisesNav-item">
                        <div class="ExpertisesNav-itemInner">
                            <div class="ExpertisesNav-itemLabel">Media</div>
                            <span className="arowup ExpertisesNav-itemArrow icon sprite-icons">
                                <BsArrowRight />
                            </span>
                            <div class="ExpertisesNav-itemColorWrap">
                                <div class="ExpertisesNav-itemColor"></div>
                            </div>
                        </div>
                    </a>
                    <a href="#PressReleases" class="ExpertisesNav-item">
                        <div class="ExpertisesNav-itemInner">
                            <div class="ExpertisesNav-itemLabel">Press Releases</div>
                            <span className="arowup ExpertisesNav-itemArrow icon sprite-icons">
                                <BsArrowRight />
                            </span>
                            <div class="ExpertisesNav-itemColorWrap">
                                <div class="ExpertisesNav-itemColor"></div>
                            </div>
                        </div>
                    </a>
                    <a href="#Events" class="ExpertisesNav-item">
                        <div class="ExpertisesNav-itemInner">
                            <div class="ExpertisesNav-itemLabel">Events</div>
                            <span className="arowup ExpertisesNav-itemArrow icon sprite-icons">
                                <BsArrowRight />
                            </span>
                            <div class="ExpertisesNav-itemColorWrap">
                                <div class="ExpertisesNav-itemColor"></div>
                            </div>
                        </div>
                    </a>
                </div>
            </section>
        
        </Container>
      </section>

      <section className="media-second-sec" >
        <Container>
            <Row>
                <Col md={12}>
                    <div className="Media-main-heading">
                        <h3>Media</h3>
                    </div>
                </Col>
            </Row>
            <div className="news-sec">
                <div className="New_news_sec">
                    <Row>
                        <Col md={6}>
                            <div className="right">
                                <img className="Allimg" src={newsimg01} alt={"news"} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="left">
                                <div className="leftbox">
                                    <h3>Actiontec</h3>
                                    <p>Febuary 24, 2024</p>
                                </div>
                                <div className="leftboxmore">
                                    <h2>
                                    Actiontec and EdgeQ Unveil Commercial Release of ASC-508: Revolutionizing Network Flexibility
                                    </h2>
                                    {/* <p>
                                    Actiontec, a leading innovator from Silicon Valley, CA, in wired and wireless access solutions for over 30 years
                                    </p> */}
                                    <a
                                        href={"https://www.actiontec.com/actiontec-and-edgeq-unveil-commercial-release-of-asc-508-revolutionizing-network-flexibility-performance-and-future-proofing-4g-5g-small-cell/"}
                                        target="_blank"
                                        aria-label="Save"
                                        rel="noopener noreferrer"
                                        className="mt-4"
                                    >
                                        <span className="arowup">
                                            <BsArrowUp />
                                        </span>{" "}
                                        Continue Reading
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="New_news_articles" id="Media">
                    <h3>More articles</h3>

                    <div className="down">
                        <table >
                            <tbody>
                                {NewsList.slice(0, visible).map((news, index) => {
                                    return (
                                        
                                        <tr key={index}>
                                            <a
                                                className="news_mainlink cursor-scalep"
                                                href={news.newslink}
                                                target="_blank"
                                                aria-label="Save"
                                                rel="noopener noreferrer"
                                            >
                                            <td className="block">
                                                <h4>{news.newsTitle}</h4>
                                            </td>
                                            <td className="block1">
                                                <p>{news.newsDate}</p>
                                            </td>
                                            <td className="block2">
                                                <h2>{news.newsDetails}</h2>
                                            </td>
                                            </a>
                                        </tr>
                                        
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="form-btn">
                            {visible == 116 ? (
                                <button onClick={() => showLess(visible)} ref={showRef2}>
                                    <span className="arowup">
                                        <BsArrowUp />
                                    </span>{" "}
                                    Hide
                                </button>
                            ) : (
                                <button onClick={() => showMore(visible)} ref={showRef}>
                                    <span className="arowup">
                                        <BsArrowUp />
                                    </span>{" "}
                                    Load More
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            {/* <div className="Desktop1">
                <Row>

                    
                    {NewsList.slice(0,visible).map((news,index)=>{
                        return(
                            <Col md={3} key={index}>
                            <div className="News-box">
                            {news.newslink ?
                            <a className="news_mainlink" href={news.newslink} target="_blank" aria-label="Save" rel="noopener noreferrer">
                                <h3>{news.newsTitle}</h3>
                                
                                <h2>{news.newsDetails}</h2>

                                <h4>{news.newsDate}</h4>

                                <a  className="news_link" href={news.newslink} target="_blank" aria-label="Save" rel="noopener noreferrer"><span className="for-arrow"><IoIosArrowForward/></span></a>
                            </a>
                            : 
                            <Link className="news_mainlink" to={news.newslinkexternal} rel="noopener noreferrer">
                                <h3>{news.newsTitle}</h3>
                                
                                <h2>{news.newsDetails}</h2>

                                <h4>{news.newsDate}</h4>                            
                                
                                <Link className="news_link" to={news.newslinkexternal} rel="noopener noreferrer"><span className="for-arrow"><IoIosArrowForward/></span></Link>
                            </Link>
                            }
                        </div>
                        
                      </Col>
                        )
                    })}
                    

                    
                    
                    
                    <Col md={12}>

                        <div className="form-btn">
                         {visible  == (116) ?  <button onClick = {()=>showLess(visible)} ref={showRef2} ><span className="arowup"><BsArrowUp/></span> Hide</button> : <button onClick = {()=>showMore(visible)} ref={showRef} ><span className="arowup"><BsArrowUp/></span> Load More</button>}   
                        </div>                

                    </Col>

                </Row>
                </div>

                <div className="Mobile1">
                <Row>

                    {NewsList.slice(0,visible1).map((news,index)=>{
                        return(
                            <Col md={3} key={index}>
                        <div className="News-box">
                        {news.newslink ?
                            <a className="news_mainlink" href={news.newslink} target="_blank" aria-label="Save" rel="noopener noreferrer">
                                <h3>{news.newsTitle}</h3>
                                
                                <h2>{news.newsDetails}</h2>

                                <h4>{news.newsDate}</h4>

                                <a  className="news_link" href={news.newslink} target="_blank" aria-label="Save" rel="noopener noreferrer"><span className="for-arrow"><IoIosArrowForward/></span></a>
                            </a>
                            : 
                            <Link className="news_mainlink" to={news.newslinkexternal} rel="noopener noreferrer">
                                <h3>{news.newsTitle}</h3>
                                
                                <h2>{news.newsDetails}</h2>

                                <h4>{news.newsDate}</h4>                            
                                
                                <Link className="news_link" to={news.newslinkexternal} rel="noopener noreferrer"><span className="for-arrow"><IoIosArrowForward/></span></Link>
                            </Link>
                            }
                        
                        </div>
                      </Col>
                        )
                    })}
                    

                    
                    
                    
                    <Col md={12}>

                    <div className="form-btn">
                    {visible1  == (116) ?  <button onClick = {()=>showLess2(visible1)} ref={showRef4} ><span className="arowup"><BsArrowUp/></span> Hide</button> : <button onClick = {()=>showMore2(visible1)} ref={showRef3} ><span className="arowup"><BsArrowUp/></span> Load More</button>}   
                    </div>                

                    </Col>

                </Row>
                </div> */}
            </div>
        </Container>
      </section>



      
{/*    
      <section className="media-third-sec" id="August">
        <Container>
            <Row>
                <Col md={9}>
                    <div className="press-left">
                        <h5>COMPANY ANNOUNCEMENTS</h5>
                        
                        <h4>Apr 19, 2023</h4>
                        <Link to="/edgeq-series-b-pr"><span style={{fontWeight:275}}>EdgeQ Closes $75M Series-B Investment and Ramps to Customers’ Demand for Its Award-Winning </span></Link>
                        
                        
                    </div>                  
                </Col>
                <Col md={3}>
                
                    <div className="press-right">
                    <a className="content-btn" target='_blank' rel='noopener noreferrer' href={PressRelease} >Download Press Kit</a>
                    </div>                 
                </Col>
                <Col md={12}>
                    <div className="press-left1 small_one">                        
                    <h4>Mar 9, 2023</h4>
                    <Link to="/edgeq-wins-multiple"><span style={{fontWeight:275}}>EdgeQ Wins Multiple 2023 Global Mobile (GLOMO) Awards Including the Coveted CTO Choice</span></Link>
                    </div>
                    <div className="press-left1 small_one">                        
                        <h4>Feb 27, 2023</h4>
                        <Link to="/edgeq-and-vodafone-debut"><span style={{fontWeight:275}}>EdgeQ and Vodafone Debut State of the Art, Software-Defined, maMIMO Open RAN Solution</span></Link>
                    </div>
                    <div className="press-left1 small_one">                        
                        <h4>Oct 26, 2022</h4>
                        <Link to="/edgeq-and-vodafone-partner"><span style={{fontWeight:275}}>EdgeQ & Vodafone Partner on World’s First 5G/4G Software Defined ORAN</span></Link>
                    </div>
                    <div className="press-left1 small_one">
                        <h4>Sep 13, 2022</h4>
                        <Link to="/edgeq-partners-with-mavenir"><span style={{fontWeight:275}}>EdgeQ Partners with Mavenir on Software Defined 4G and 5G Mobile Networks</span></Link>
                    </div>
                     <div className="press-left1 small_one">
                        <h4>Jun 15, 2022 </h4>
                        <Link to="/edgeq-showcases-and-samples"><span style={{fontWeight:275}}>EdgeQ Showcases and Samples 5G Small Cell and O-RAN PCIe Accelerator Card</span></Link>
                    </div>
                    <div className="press-left1 small_one">                        
                        <h4>Feb 15, 2022</h4>
                        <Link to="/edgeq-adds-former-fedral"><span style={{fontWeight:275}}>EdgeQ Adds Former Federal Communications Commission Chairman Ajit Pai to its Advisory Board</span></Link>
                    </div> 
                    <div className="press-left1 small_one">                        
                        <h4>Aug 18, 2021</h4>
                        <Link to="/edgeq-samples-worlds-first"><span style={{fontWeight:275}}>EdgeQ Samples World’s First Software Defined 5G Base Station-on-a-Chip to Market Leaders in Enterprise, Telco and Hyperscale Cloud</span></Link>
                    </div> 
                    <div className="press-left1 small_one">                        
                        <h4>Jun 15, 2021</h4>
                        <Link to="/edgeq-launches-industrys-first"><span style={{fontWeight:275}}>EdgeQ Launches Industry’s First 5G Chipset-as-a-Service for 5G Wireless Infrastructure Market</span></Link>
                    </div>  
                    <div className="press-left1 small_one">
                        <h4>Jan 26, 2021</h4> <Link to="/edgeq-adds-former-qualcomm"><span style={{fontWeight:275}}>EdgeQ Adds Former Qualcomm CEO Paul Jacobs & CTO Matt Grob as Advisors to Disrupt the Current Closed RAN Ecosystem with RISC-V based Highly Programmable 5G and AI Platform</span></Link>
                    </div>   
                    <div className="press-left1 small_one">
                        <h4>Nov 17, 2020</h4>  <Link to="/edgeq-raises-51m-in"><span style={{fontWeight:275}}>EdgeQ Raises $51M in Funding to Unify 5G and AI in an Open and Programmable Platform to Ignite Edge Networks</span></Link>                                    
                    </div>                 
                </Col>
            </Row>
        </Container>
    </section> */}


<section className="media-third-sec" id="PressReleases">
                    <Container>
                        <div className="Media-main-heading">
                            <h3>Press Releases</h3>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="press-left">
                                    {/* <h5>COMPANY ANNOUNCEMENTS</h5> */}

                                    <h4>Nov 16, 2023</h4>
                                    <Link to="/edgeq-named-as-a-cool">
                                        <span>
                                        EdgeQ Named as a Cool Vendor in the 2023 Gartner® Cool Vendors™ for Communications Service
Providers Report
                                            {" "}
                                        </span>
                                    </Link>
                                </div>
                            </Col>
                            {/* <Col md={3}>
                                <div className="press-right">
                                    <a
                                        className="content-btn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={PressRelease}
                                    >
                                        Download Press Kit
                                    </a>
                                </div>
                            </Col> */}
                            <Col md={12}>
   
                                <div className="press-list">
                                    {pressReleasesData
                                    .slice(0, visiblePressCount)
                                    .map((release, index) => (
                                        <div key={index} className="press-left1 small_one">
                                            <h4>{release.date}</h4>
                                            <Link to={release.Link}>
                                                <span style={{ fontWeight: 275 }}>
                                                    {release.LinkText}
                                                </span>
                                            </Link>
                                        </div>
                                    ))}
                                </div>

                                {visiblePressCount < pressReleasesData.length && (
                                    <div className="form-btn white">
                                        <button onClick={handleLoadMore}>
                                            <span className="arowup">
                                                <BsArrowUp />
                                            </span>{" "}
                                            Load More
                                        </button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>

    <section className="media-five-sec" id="Events">
        <Container>
            <Row>
                <Col md={12}>
                    <div className="Media-main-heading">
                        <h3>Events</h3>
                    </div>
                </Col>
            </Row>
        </Container>
       

        <div ref={sliderRef1} className="keen-slider past-event" onMouseEnter={()=>setIsDrag(true) } onMouseLeave={()=>setIsDrag(false)}>
                <div className="keen-slider__slide meetour-head">
                      <h3>
                          <span className="right-arrow">
                          <BsArrowRight />
                          </span>
                          Past 
                            Events
                      </h3>
                  </div>
                     {eventsSlider.map((evtSlides,index)=>{
                       return(
                        <div className={`keen-slider__slide number-slide${index + 1 }`} key={index} >
                            <div className="event-box" onClick={()=>showEvtDetails(index)} onMouseEnter={()=>setIsDrag(false) } onMouseLeave={()=>setIsDrag(true)}>
                                <div className="slide-img Allimg">
                                    <img src={evtSlides.evtImg} alt={evtSlides.evtName}/>
                                </div>
                                <div className="slide-text">                                
                                    <h4>{evtSlides.date}</h4>
                                    <h2>{evtSlides.evtName}</h2>
                                    <div className="upcombtn-box">
                                    <a href={evtSlides.evtLink} target="_blank" rel="noopener noreferrer">Explore More <span className="for-arrow"><IoIosArrowForward/></span></a>
                                    </div>
                                </div>
                            </div>
                        </div> 
                       )
                     })}

                     </div>

                      
                   

                    
    </section>


    <section className="media-five-sec" id="Events">


    <div ref={sliderRef1} className="keen-slider past-event" onMouseEnter={()=>setIsDrag(true) } onMouseLeave={()=>setIsDrag(false)}>
                <div className="keen-slider__slide meetour-head">
                      <h3>
                          <span className="right-arrow">
                          <BsArrowRight />
                          </span>
                          Upcoming 
                            Events
                      </h3>
                  </div>
                     {upcomingeventsSlider.map((evtSlides,index)=>{
                       return(
                        <div className={`keen-slider__slide number-slide${index + 1 }`} key={index} >
                            <div className="event-box" onClick={()=>showEvtDetails(index)} onMouseEnter={()=>setIsDrag(false) } onMouseLeave={()=>setIsDrag(true)}>
                                <div className="slide-img Allimg">
                                    <img src={evtSlides.evtImg} alt={evtSlides.evtName}/>
                                </div>
                                <div className="slide-text">                                
                                    <h4>{evtSlides.date}</h4>
                                    <h2>{evtSlides.evtName}</h2>
                                    <div className="upcombtn-box">
                                    <a href={evtSlides.evtLink} target="_blank" rel="noopener noreferrer">Explore More <span className="for-arrow"><IoIosArrowForward/></span></a>
                                    </div>
                                </div>
                            </div>
                        </div> 
                       )
                     })}

    </div>

       

         

                     
                   

                    
    </section>

    {/* <section id="Videos">
    <VideoSlider location={location}/>
    </section> */}
    

    </main>



   
    </Layout>
  );
};
export default Media;
